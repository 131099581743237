<!-- 动态资讯 -->
<template>
  <div class='consult-container'>
    <img class="banner" src="../../assets/images/banner3.jpg" alt="">
    <div class="container">
      <h1>动态资讯</h1>
      <div class="info-wrap">
        <img src="../../assets/images/img8.png" alt="">
        <div class="detail-container">
          <div class="title-c">
            <div class="title">遵义市红花岗区举办适应性发展课程建设推进会</div>
            <div class="time">
              <i class="el-icon-time"></i>
              <span> 2021年3月11日</span>
            </div>
          </div>
          <div class="info">
            2021年3月11日，遵义市红花岗区教育局在区第二幼儿园会议室举行了贵州省幼儿园适应性发展课程推进会，通过专题讲座活动的形式，对本学期课程建设方案进行系统梳理。贵阳幼儿师范高等专科学校副教授李凤霞、红花岗区教育局基础教育股股长助理曹丽娟、红花岗区学前教育专职教研员阮丽及红花岗区13所幼儿园园长、业务副园长、保教主任参加了本次专题研讨与学习活动
          </div>
          <div class="more">
            <a href="https://mp.weixin.qq.com/s/bY9LCiFYIx92uYtlPfgOaA">
              <span>查看更多</span>
              <i class="el-icon-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {

    };
  },
  //方法集合
  methods: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
}
</script>
<style lang='less' scoped>
.consult-container {
  margin-bottom: 167px;

  .banner {
    width: 100%;
  }

  .container {
    margin-top: 100px;
    overflow: visible;
    h1 {
      font-weight: 400;
      font-size: 42px;
      color: #333333;
      text-align: center;
    }

    .info-wrap {
      width: 1150px;
      height: 340px;
      background: rgba(255, 255, 255, 0.39);
      box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
      display: flex;
      margin-top: 100px;
      padding: 42px 16px 42px 42px;
      border-left: 4px solid #FFA60A;

      .detail-container {
        padding-left: 36px;

        .title-c {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title {
            font-size: 20px;
            color: #474747;
          }

          .time {
            color: #FFA60A;
            font-size: 13px;
          }
        }

        .info {
          font-size: 14px;
          color: #6A6A6A;
          line-height: 24px;
          color: #6A6A6A;
          padding-top: 20px;
          padding-right: 24px;
        }

        a {
          width: 118px;
          height: 30px;
          background: #FFA60A;
          border-radius: 60px;
          font-size: 14px;
          color: #fff;
          display: block;
          text-align: center;
          line-height: 30px;
          margin-top: 96px;

        }
      }
    }


  }
}
</style>